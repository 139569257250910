export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isSSR = () => typeof document === 'undefined'

export const getImageSizeUrl = (data = {}, size = null, fallback = null) => {
  if (!data?.url || !data?.url?.[size]) {
    return fallback
  }

  return data.url[size]
}

export const rand = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

export const nl2br = str => {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  const breakTag = '<br />'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const hasLocalStorage =
  !isSSR() &&
  !!window.localStorage &&
  typeof localStorage.getItem === 'function' &&
  typeof localStorage.setItem === 'function' &&
  typeof localStorage.removeItem === 'function'
