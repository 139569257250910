import { isDevelopment } from '~/utils/helpers'

const config = {
  functions: {
    url: null, // is set through middleware
  },
  api: {
    base: 'https://cms.probots.io',
    path: '/wp-json/probots/v1',
  },
  debug: false,
  cache: {
    provider: 'cloudflareKV',
    providerConfig: {
      ttl: 1000 * 60 * 60 * 24,
      max: 50,
      store: 'KV_CACHE',
    },
    enabled: true,
  },
  sentry: {
    active: true,
    dsn: 'https://64a28014a9a44e08b98b2062ed1ea919@o71063.ingest.sentry.io/6524893',
  },
  lang: {
    showSwitcher: true,
    switchQueryString: 'sl',
    languages: ['de', 'en'],
    default: 'de',
    redirectIfLanguageMismatch: false,
    detections: {
      searchParam: true,
      cookie: false,
      parseUrl: true,
      browser: false,
    },
  },
}

if (isDevelopment()) {
  config.api = {
    base: 'http://cms.probots.prbts',
    path: '/wp-json/probots/v1',
  }

  config.cache.enabled = false

  config.debug = true

  config.sentry = {
    active: false,
    dsn: null,
  }
}

export default config
